import { Test_OrgTestLayoutFragment } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import { gql } from "~/__generated__"
import toast from "react-hot-toast"
import { SettingToggle } from "~/ui/forms/SettingToggle"

export const TestSettingToggle = ({
  id,
  title,
  subTitle,
  fieldName,
  icon,
  test,
}: {
  id: string
  title: string
  subTitle: string
  fieldName: string
  icon: React.FC<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
  test: Test_OrgTestLayoutFragment
}) => {
  const fieldNameKey = fieldName as keyof Test_OrgTestLayoutFragment

  const [testUpdate, { loading }] = useSafeMutation(TEST_UPDATE_MUTATION)

  const handleCheckedChange = async (checked: boolean) => {
    const { data, errors } = await testUpdate({
      variables: {
        input: {
          testId: test.id,
          name: test.name as string,
          [fieldName]: checked,
        },
      },
    })

    if (errors) {
      toast.error("Error updating setting.")
      console.log(errors)
    } else if (data?.testUpdate?.test) {
      toast.success(`${title} setting updated`)
    } else {
      toast.error("Error updating setting.")
    }
  }

  return (
    <SettingToggle
      id={id}
      title={title}
      subTitle={subTitle}
      icon={icon}
      checked={test[fieldNameKey]}
      onCheckedChange={handleCheckedChange}
      loading={loading}
    />
  )
}

const TEST_UPDATE_MUTATION = gql(`
  mutation TestSettingsUpdate($input: TestUpdateInput!) {
    testUpdate(input: $input) {
      test {
        id
        requireEmailVerification
        requirePhoneVerification
        requireVideo
        requireAudio
      }
    }
  }
`)
