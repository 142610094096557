import { NavLink, Outlet } from "react-router-dom"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { useCurrentOrganization } from "~/common/useCurrentOrganization"
import { cn } from "~/common/shadcn-utils"
import { HeaderNavigation } from "~/ui/HeaderNavigation"
import { Footer } from "~/ui/Footer"
import {
  candidateTestsPath,
  organizationTestsPath,
  organizationSettingsPath,
  userProfilePath,
  organizationUserProfilePath,
  organizationCandidatesPath,
  adminCandidatesPath,
  adminOrganizationsPath,
  adminTestsPath,
  adminAdminsPath,
} from "~/common/paths"
import { Button } from "~/shadcn/ui/button"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import toast from "react-hot-toast"
import { UserDetailsDialog } from "~/users/UserDetailsDialog"
import { useQuery } from "@apollo/client"

export const AppLayout = ({ admin = false }: { admin?: boolean }) => {
  const { currentUser } = useCurrentUserMaybe()
  const currentOrganization = useCurrentOrganization()

  const [runStopImpersonating] = useSafeMutation(STOP_IMPERSONATING_MUTATION)

  const stopImpersonating = async () => {
    const { errors } = await runStopImpersonating({
      variables: {
        input: {},
      },
    })
    if (errors) {
      toast.error("An error occurred. Please try again.")
      return
    }
    toast.success(
      `You are no longer impersonating ${currentUser?.email}. Redirecting...`
    )
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }

  return (
    <div
      className={cn(
        "flex-1 flex flex-col w-full",
        currentUser?.impersonator?.id && "mb-16"
      )}
    >
      <HeaderNavigation
        currentUser={currentUser}
        currentOrganization={currentOrganization}
        containerClassName={admin ? "bg-primary" : "bg-gray-F7F7F5"}
        admin={admin}
      >
        <div className="mx-auto flex items-center">
          {admin ? (
            <>
              <HeaderLink
                to={adminOrganizationsPath.pattern}
                label="Companies"
                admin
              />
              <HeaderLink to={adminTestsPath.pattern} label="Tests" admin />
              <HeaderLink
                to={adminCandidatesPath.pattern}
                label="Candidates"
                admin
              />
              <HeaderLink to={adminAdminsPath.pattern} label="Admins" admin />
            </>
          ) : currentOrganization ? (
            <>
              <HeaderLink
                to={organizationTestsPath({
                  organizationId: currentOrganization.id,
                })}
                label="Tests"
              />
              <HeaderLink
                to={organizationCandidatesPath({
                  organizationId: currentOrganization.id,
                })}
                label="Candidates"
                withCount={
                  <CandidateTestsCountValue
                    organizationId={currentOrganization.id}
                  />
                }
              />
              <HeaderLink
                to={organizationUserProfilePath({
                  organizationId: currentOrganization.id,
                })}
                label="Profile"
              />
              <HeaderLink
                to={organizationSettingsPath({
                  organizationId: currentOrganization.id,
                })}
                label="Organization"
              />
            </>
          ) : currentUser ? (
            <>
              <HeaderLink to={candidateTestsPath.pattern} label="Tests" />
              <HeaderLink to={userProfilePath.pattern} label="Profile" />
            </>
          ) : null}
        </div>
      </HeaderNavigation>

      <div className="flex-1 w-full max-w-[1024px] mx-auto">
        <Outlet />
      </div>

      <div className="w-full max-w-[1024px] mx-auto">
        <Footer className="pt-10 pb-6" />
      </div>

      {currentUser?.impersonator?.id && (
        <div className="fixed bottom-0 left-0 right-0 w-screen h-16 bg-communist flex gap-4 items-center justify-center text-white">
          <div>You are currently impersonating {currentUser?.email}.</div>
          <Button onClick={stopImpersonating} variant="outline-light">
            Stop Impersonating
          </Button>
        </div>
      )}

      <UserDetailsDialog />
    </div>
  )
}

const CandidateTestsCountValue = ({
  organizationId,
}: {
  organizationId: string
}) => {
  const { data } = useQuery(ORG_CANDIDATE_TESTS_COUNT_QUERY, {
    variables: {
      organizationId,
    },
    pollInterval: 1000 * 10,
  })

  let candidateTestsCount = null
  if (data?.organization) {
    candidateTestsCount = data.organization.candidateTestsCount
  }
  return <>{candidateTestsCount}</>
}

const HeaderLink = ({
  label,
  to,
  admin = false,
  withCount,
}: {
  label: string
  to: string
  admin?: boolean
  withCount?: React.ReactNode
}) => (
  <NavLink
    className={({ isActive }) =>
      cn("block px-6 py-4 rounded text-gray-333 font-medium", {
        "text-white": admin,
        "bg-blue-2478D9": admin && isActive,
        "bg-gray-EFEFED": !admin && isActive,
      })
    }
    to={to}
  >
    <div className="flex items-center gap-1">
      {label}
      {withCount && (
        <div className="ml-2 text-white text-xs w-6 h-6 rounded-full bg-primary flex items-center justify-center text-[8px]">
          {withCount}
        </div>
      )}
    </div>
  </NavLink>
)

const STOP_IMPERSONATING_MUTATION = gql(`
  mutation UserStopImpersonating($input: UserStopImpersonatingInput!) {
    userStopImpersonating(input: $input) {
      user { id }
    }
  }
`)

export const ORG_CANDIDATE_TESTS_COUNT_QUERY = gql(`
  query OrganizationCandidateTestsCount($organizationId: ID!) {
    organization(organizationId: $organizationId) {
    	id
      candidateTestsCount
    }
  }
`)
