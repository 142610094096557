import { PageHeader } from "~/ui/PageHeader"
import { gql } from "~/__generated__"
import { useQuery } from "@apollo/client"
import { Test_AdminTestsTableFragment } from "~/__generated__/graphql"

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHead,
  TableRow,
} from "~/ui/tables/Table"
import { Error } from "~/ui/Error"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { formatDate, formatTime } from "~/common/dates"
import { LinkButton } from "~/shadcn/ui/button"
import { usePagination } from "~/common/usePagination"
import { Pagination } from "~/ui/Pagination"
import { adminTestPath } from "~/common/paths"
import {
  TEST_ACCESS_LEVELS,
  TEST_PUBLISH_STATUSES,
} from "~/questions/QuestionGroupForm/utils"
import { Divider } from "~/ui/Divider"
import { Link } from "react-router-dom"

const PER_PAGE = 20

const TestsTable = ({ tests }: { tests: Test_AdminTestsTableFragment[] }) => {
  return (
    <Table className="table-fixed text-sm md:text-md">
      <TableHeader>
        <TableRow>
          <TableHead className="">Name</TableHead>
          <TableHead className="w-32 text-right">Created Date</TableHead>
          <TableHead className="w-32 text-right">Test Status</TableHead>
          <TableHead className="w-32 text-right">Access</TableHead>
          <TableHead className="w-32 text-right">Questions</TableHead>
          <TableHead className="w-32 text-right">Responses</TableHead>
          <TableHead className="w-32 text-right">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {tests.map((test) => (
          <TableRow key={test.id}>
            <TableCell className="truncate">
              <Link
                to={adminTestPath({ testId: test.id })}
                className="hover:underline"
              >
                {test.name}
              </Link>
              <div className="text-sm text-gray-999">
                {test.organization.name}
              </div>
            </TableCell>
            <TableCell className="text-right">
              <p>{formatDate(test.createdAt)}</p>
              <p>{formatTime(test.createdAt)}</p>
            </TableCell>
            <TableCell className="text-right">
              {TEST_PUBLISH_STATUSES[test.publishStatus]}
            </TableCell>
            <TableCell className="text-right">
              {TEST_ACCESS_LEVELS[test.access]}
            </TableCell>
            <TableCell className="text-right">
              {test.questionGroupsCount}
            </TableCell>
            <TableCell className="text-right">
              {test.completedCandidateTestsCount}
            </TableCell>
            <TableCell className="text-right">
              <LinkButton to={adminTestPath({ testId: test.id })}>
                View
              </LinkButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export const AdminTestsScreen = () => {
  const { after, paginate, page } = usePagination({ perPage: PER_PAGE })
  const { data, error, loading } = useQuery(TESTS_QUERY_DOCUMENT, {
    variables: { first: PER_PAGE, after },
  })

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading tests." />

  const tests = data.adminTests.edges.map((e) => e.node) || []

  return (
    <div className="container mx-auto flex flex-col gap-8 mt-8 pb-12">
      <PageHeader title="Tests" />
      <div>
        <div>
          Transcoding Success:{" "}
          {Math.round(data.adminStats.transcodingSuccessPercentage * 100)}%
        </div>
        <div>
          Transcription Success:{" "}
          {Math.floor(data.adminStats.transcriptSuccessPercentage * 100)}%
        </div>
      </div>
      <Divider />
      {tests.length > 0 ? (
        <TestsTable tests={tests} />
      ) : (
        <div>No tests yet.</div>
      )}
      {data.adminTests.pageCount > 1 && (
        <Pagination
          page={page}
          pageCount={data.adminTests.pageCount}
          paginate={paginate}
        />
      )}
    </div>
  )
}

gql(`
  fragment Test_AdminTestsTable on Test {
    id
    name
    createdAt
    publishStatus
    access
    questionGroupsCount
    completedCandidateTestsCount

    organization {
      name
    }
  }
`)

const TESTS_QUERY_DOCUMENT = gql(`
  query AdminTests($first: Int!, $after: String) {
    adminStats {
      transcodingSuccessPercentage
      transcriptSuccessPercentage
    }
    adminTests(first: $first, after: $after) {
      totalCount
      pageCount(first: $first)
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...Test_AdminTestsTable
        }
      }
    }
  }
`)
