import { ThumbsUp, ThumbsDown } from "lucide-react"
import CircleX from "~/images/icons/circle-x.svg?react"
import {
  QuestionGroup_Form_UserAnswersFragment,
  AiExampleEnum,
} from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import toast from "react-hot-toast"
import { QUESTION_RESPONSE_MUTATION } from "~/questions/ManualScoreDialog"
import { Link, useParams } from "react-router-dom"
import { organizationQuestionResponsePath } from "~/common/paths"
import invariant from "tiny-invariant"

export const UserExampleAnswer = ({
  answer,
  isGood = false,
}: {
  answer: QuestionGroup_Form_UserAnswersFragment
  isGood?: boolean
}) => {
  const [testUpdate, { loading }] = useSafeMutation(
    QUESTION_RESPONSE_MUTATION,
    {
      refetchQueries: ["QuestionGroup"],
    }
  )

  const { organizationId, testId } = useParams<{
    organizationId: string
    testId: string
  }>()
  invariant(organizationId, "organizationId is required")
  invariant(testId, "testId is required")

  const doUpdate = async () => {
    const { data, errors } = await testUpdate({
      variables: {
        input: {
          questionResponseId: answer.id,
          aiExample: AiExampleEnum.None,
        },
      },
    })

    const success = !!data?.questionResponseUpdate?.questionResponse || !errors

    if (errors) {
      toast.error("Failed to remove response example.")
      console.log(errors)
    }

    return success
  }

  let answer_text =
    answer.textResponse ||
    answer.questionAttempts[answer.questionAttempts.length - 1]?.transcript

  if (answer_text) {
    answer_text =
      answer_text.slice(0, 300) + (answer_text.length > 300 ? "..." : "")
  }

  return (
    <div className="rounded bg-white border border-gray-E6E6E3 divide-y divide-y-gray-E6E6E3">
      <div className="p-4 text-gray-333 flex items-center justify-between">
        <div className="flex flex-col gap-1">
          <span className="font-semibold">
            {answer.candidateTest.user.name}
          </span>
          <span className="text-sm text-gray-999">
            {answer.updatedAt}
            <Link
              className="text-primary ml-4 hover:cursor-pointer hover:underline"
              to={organizationQuestionResponsePath({
                organizationId,
                testId,
                questionResponseId: answer.id,
              })}
            >
              View Response
            </Link>
          </span>
        </div>
        <div className="flex items-center gap-8">
          {isGood ? (
            <div className="text-success text-sm font-semibold flex gap-2 items-center">
              <ThumbsUp size={14} />
              Marked as good response example.
            </div>
          ) : (
            <div className="text-error text-sm font-semibold flex gap-2 items-center">
              <ThumbsDown size={14} />
              Marked as bad response example.
            </div>
          )}
          <button className="text-error" onClick={doUpdate} disabled={loading}>
            <CircleX className="h-5 w-5 text-error" />
          </button>
        </div>
      </div>
      <p className="p-4 text-gray-333">{answer_text}</p>
    </div>
  )
}
