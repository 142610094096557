import { GravatarImage } from "./GravatarImage"
import { cn } from "~/common/shadcn-utils"

export const UserWithImage = ({
  name,
  email,
  phone,
  nameClassName,
  emailClassName,
  iconClassName,
  className,
  meta,
}: {
  name?: string | null
  email: string
  phone?: string | null
  nameClassName?: string
  emailClassName?: string
  iconClassName?: string
  className?: string
  meta?: React.ReactNode
}) => (
  <div className={cn("flex flex-row gap-3 items-center", className)}>
    <GravatarImage name={name} email={email} className={iconClassName} />
    <div className="flex flex-col">
      <div className={cn("text-base", nameClassName)}>{name}</div>
      <div className={cn("text-gray-999 hidden md:block", emailClassName)}>
        {[email, phone].filter((v) => v).join(" - ")}
      </div>
      {meta}
    </div>
  </div>
)
