import { useOrganizationTest } from "~/common/useOrganizationTest"
import { useQuery } from "@apollo/client"
import { useParams, useNavigate } from "react-router-dom"
import invariant from "tiny-invariant"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { EmptyData } from "~/components/EmptyData"
import { Error } from "~/ui/Error"
import { CandidateTestLayout } from "~/layouts/CandidateTestLayout"
import {
  organizationQuestionResponsePath,
  organizationTestResponsesPath,
  organizationCandidatesPath,
} from "~/common/paths"
import { useOrganizationId } from "~/common/useCurrentOrganization"
import { TestQuestionResponsesList } from "~/tests/TestQuestionResponsesList"
import { Navigation } from "~/organizations/Navigation"
import { CANDIDATE_TEST_RESPONSES_QUERY_DOCUMENT } from "~/tests/candidateTestQueries"
import { ShareButton } from "~/tests/ShareUI"
import { EmailCandidateDialog } from "~/organizations/EmailCandidateDialog"
import { CandidateTestNoteDialog } from "~/organizations/CandidateTestNoteDialog"
import { CandidateTestNotesList } from "~/organizations/CandidateTestNotesList"
import { ToggleArchivedButton } from "~/tests/ToggleArchivedAtButton"

export const OrganizationTestResponseScreen = () => {
  const { test } = useOrganizationTest()
  const { candidateTestId } = useParams()
  invariant(candidateTestId)
  const navigate = useNavigate()

  const { loading, data, error } = useQuery(
    CANDIDATE_TEST_RESPONSES_QUERY_DOCUMENT,
    {
      variables: { candidateTestId },
    }
  )

  const organizationId = useOrganizationId()
  const testId = test?.id || ""

  const onArchived = () => {
    navigate(organizationCandidatesPath({ organizationId }))
  }

  return (
    <CandidateTestLayout
      test={test}
      candidateTest={data?.candidateTest}
      backLabel="Responses"
      backPath={organizationTestResponsesPath({ organizationId, testId })}
      navigation={
        <Navigation organizationId={organizationId} testId={testId} />
      }
      rightProfile={
        <div className="flex items-center gap-2">
          <EmailCandidateDialog candidateTestId={candidateTestId} />
          <CandidateTestNoteDialog candidateTestId={candidateTestId} />
          {data && (
            <>
              <ToggleArchivedButton
                candidateTest={data?.candidateTest}
                showIcon
                onChange={onArchived}
              />
              <ShareButton candidateTestId={data.candidateTest.id} />
            </>
          )}
        </div>
      }
    >
      {error ? <Error message="Error loading responses." /> : null}
      {loading ? (
        <LoadingIndicatorCentered />
      ) : data && data.candidateTestQuestionResponses.nodes.length > 0 ? (
        <>
          <CandidateTestNotesList notes={data?.candidateTest.notes} />
          <TestQuestionResponsesList
            data={data.candidateTestQuestionResponses.nodes}
            viewPath={(response) =>
              organizationQuestionResponsePath({
                organizationId,
                testId,
                questionResponseId: response.id,
              })
            }
            onlyAnswered
          />
        </>
      ) : (
        <EmptyData>No responses yet.</EmptyData>
      )}
    </CandidateTestLayout>
  )
}
