import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"
import * as paths from "~/common/paths"
import { SentryRouteErrorFallback } from "./components/ErrorBoundary"
import { LoadingScreen } from "./screens/LoadingScreen"
import { SignInScreen } from "./screens/SignInScreen"
import { SignInTokenScreen } from "./screens/SignInTokenScreen"
import { RequireUserSignedOut } from "./layouts/RequireUserSignedOut"
import { RequireUserSignedIn } from "./layouts/RequireUserSignedIn"
import { LandingLayout } from "./layouts/LandingLayout"
import { ShareLayout } from "./layouts/ShareLayout"
import { CreateAccountScreen } from "./screens/CreateAccountScreen"
import { CandidateTestsScreen } from "./screens/Candidate/CandidateTestsScreen"
import { AppLayout } from "./layouts/AppLayout"
import { CreateOrganizationScreen } from "./screens/CreateOrganizationScreen"
import { OrganizationTestsScreen } from "./screens/Organization/OrganizationTestsScreen"
import { OrganizationCreateTestScreen } from "./screens/Organization/OrganizationCreateTestScreen"
import { OrganizationTestOverviewScreen } from "./screens/Organization/OrganizationTestOverviewScreen"
import { OrganizationTestPreviewScreen } from "./screens/Organization/OrganizationTestPreviewScreen"
import { OrganizationTestQuestionsScreen } from "./screens/Organization/OrganizationTestQuestionsScreen"
import { OrganizationTestAccessScreen } from "./screens/Organization/OrganizationTestAccessScreen"
import { OrganizationTestResponsesScreen } from "./screens/Organization/OrganizationTestResponsesScreen"
import { OrganizationTestResponseScreen } from "./screens/Organization/OrganizationTestResponseScreen"
import { OrganizationQuestionGroupEditScreen } from "./screens/Organization/OrganizationQuestionGroupEditScreen"
import { OrganizationQuestionGroupCreateScreen } from "./screens/Organization/OrganizationQuestionGroupCreateScreen"
import { OrganizationCandidatesScreen } from "./screens/Organization/OrganizationCandidatesScreen"
import { OrganizationSettingsScreen } from "./screens/Organization/OrganizationSettingsScreen"
import { CandidateTestDetailsScreen } from "./screens/Candidate/CandidateTestDetailsScreen"
import { CandidateTestContinueScreen } from "./screens/Candidate/CandidateTestContinueScreen"
import { MarketingScreen } from "./screens/MarketingScreen"
import { TermsOfServiceScreen } from "./screens/Landing/TermsOfServiceScreen"
import { PrivacyPolicyScreen } from "./screens/Landing/PrivacyPolicyScreen"
import { CookiePolicyScreen } from "./screens/Landing/CookiePolicyScreen"
import { PromotionalTestsScreen } from "./screens/PromotionalTestsScreen"
import { UserProfileScreen } from "./screens/User/UserProfileScreen"
import { AdminCandidatesScreen } from "./screens/Admin/AdminCandidatesScreen"
import { RequireAdmin } from "./layouts/RequireAdmin"
import { AdminCandidateScreen } from "./screens/Admin/AdminCandidateScreen"
import { OrganizationTestQuestionResponseScreen } from "./screens/Organization/OrganizationTestQuestionResponseScreen"
import { AdminOrganizationsScreen } from "./screens/Admin/AdminOrganizationsScreen"
import { AdminOrganizationScreen } from "./screens/Admin/AdminOrganizationScreen"
import { AdminTestsScreen } from "./screens/Admin/AdminTestsScreen"
import { AdminTestScreen } from "./screens/Admin/AdminTestScreen"
import { AdminAdminsScreen } from "./screens/Admin/AdminAdminsScreen"
import { AdminCandidateTestScreen } from "./screens/Admin/AdminCandidateTestScreen"
import { AdminQuestionResponseScreen } from "./screens/Admin/AdminQuestionResponseScreen"
import { OrganizationTestAIWizardScreen } from "./screens/Organization/OrganizationTestAIWizardScreen"
import { OrganizationTestQuestionImportScreen } from "./screens/Organization/OrganizationTestQuestionImportScreen"
import { ShareCandidateTestScreen } from "./screens/Share/ShareCandidateTestScreen"
import { ShareCandidateTestResponseScreen } from "./screens/Share/ShareCandidateTestResponseScreen"

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<SentryRouteErrorFallback />}>
      <Route path={paths.loadingPath.pattern} element={<LoadingScreen />} />

      <Route path={paths.marketingPath.pattern} element={<MarketingScreen />} />

      <Route
        path={paths.promotionalTestsPath.pattern}
        element={<PromotionalTestsScreen />}
      />

      <Route element={<LandingLayout />}>
        <Route
          path={paths.termsOfServicePath.pattern}
          element={<TermsOfServiceScreen />}
        />
        <Route
          path={paths.privacyPolicyPath.pattern}
          element={<PrivacyPolicyScreen />}
        />
        <Route
          path={paths.cookiePolicyPath.pattern}
          element={<CookiePolicyScreen />}
        />
      </Route>

      <Route element={<ShareLayout />}>
        <Route
          path={paths.shareCandidateTestPath.pattern}
          element={<ShareCandidateTestScreen />}
        />
        <Route
          path={paths.shareCandidateTestResponsePath.pattern}
          element={<ShareCandidateTestResponseScreen />}
        />
      </Route>

      <Route element={<AppLayout />}>
        <Route
          path={paths.candidateTestPath.pattern}
          element={<CandidateTestDetailsScreen />}
        />
      </Route>

      <Route element={<RequireAdmin />}>
        <Route element={<AppLayout admin />}>
          <Route
            path={paths.adminAdminsPath.pattern}
            element={<AdminAdminsScreen />}
          />
          <Route
            path={paths.adminCandidatesPath.pattern}
            element={<AdminCandidatesScreen />}
          />
          <Route
            path={paths.adminCandidatePath.pattern}
            element={<AdminCandidateScreen />}
          />
          <Route
            path={paths.adminCandidateTestPath.pattern}
            element={<AdminCandidateTestScreen />}
          />
          <Route
            path={paths.adminOrganizationsPath.pattern}
            element={<AdminOrganizationsScreen />}
          />
          <Route
            path={paths.adminOrganizationPath.pattern}
            element={<AdminOrganizationScreen />}
          />
          <Route
            path={paths.adminQuestionResponsePath.pattern}
            element={<AdminQuestionResponseScreen />}
          />
          <Route
            path={paths.adminTestsPath.pattern}
            element={<AdminTestsScreen />}
          />
          <Route
            path={paths.adminTestPath.pattern}
            element={<AdminTestScreen />}
          />
        </Route>
      </Route>

      <Route element={<RequireUserSignedIn />}>
        <Route element={<AppLayout />}>
          <Route
            path={paths.candidateTestsPath.pattern}
            element={<CandidateTestsScreen />}
          />
          <Route
            path={paths.candidateTestContinuePath.pattern}
            element={<CandidateTestContinueScreen />}
          />

          <Route
            path={paths.organizationTestsPath.pattern}
            element={<OrganizationTestsScreen />}
          />
          <Route
            path={paths.organizationCreateTestPath.pattern}
            element={<OrganizationCreateTestScreen />}
          />
          <Route
            path={paths.organizationTestOverviewPath.pattern}
            element={<OrganizationTestOverviewScreen />}
          />
          <Route
            path={paths.organizationTestAIWizardPath.pattern}
            element={<OrganizationTestAIWizardScreen />}
          />
          <Route
            path={paths.organizationTestQuestionsPath.pattern}
            element={<OrganizationTestQuestionsScreen />}
          />
          <Route
            path={paths.organizationTestQuestionImportPath.pattern}
            element={<OrganizationTestQuestionImportScreen />}
          />
          <Route
            path={paths.organizationTestPreviewPath.pattern}
            element={<OrganizationTestPreviewScreen />}
          />
          <Route
            path={paths.organizationTestAccessPath.pattern}
            element={<OrganizationTestAccessScreen />}
          />
          <Route
            path={paths.organizationTestResponsesPath.pattern}
            element={<OrganizationTestResponsesScreen />}
          />
          <Route
            path={paths.organizationTestResponsePath.pattern}
            element={<OrganizationTestResponseScreen />}
          />
          <Route
            path={paths.organizationQuestionResponsePath.pattern}
            element={<OrganizationTestQuestionResponseScreen />}
          />
          <Route
            path={paths.organizationQuestionGroupCreatePath.pattern}
            element={<OrganizationQuestionGroupCreateScreen />}
          />
          <Route
            path={paths.organizationQuestionGroupEditPath.pattern}
            element={<OrganizationQuestionGroupEditScreen />}
          />
          <Route
            path={paths.organizationCandidatesPath.pattern}
            element={<OrganizationCandidatesScreen />}
          />
          <Route
            path={paths.organizationUserProfilePath.pattern}
            element={<UserProfileScreen />}
          />
          <Route
            path={paths.userProfilePath.pattern}
            element={<UserProfileScreen />}
          />
          <Route
            path={paths.organizationSettingsPath.pattern}
            element={<OrganizationSettingsScreen />}
          />
        </Route>

        <Route element={<LandingLayout />}>
          <Route
            path={paths.createOrganizationPath.pattern}
            element={<CreateOrganizationScreen />}
          />
        </Route>
      </Route>

      <Route element={<RequireUserSignedOut />}>
        <Route element={<LandingLayout />}>
          <Route path={paths.signInPath.pattern} element={<SignInScreen />} />
          <Route
            path={paths.createAccountPath.pattern}
            element={<CreateAccountScreen />}
          />
          <Route
            path={paths.signInTokenPath.pattern}
            element={<SignInTokenScreen />}
          />
        </Route>
      </Route>
    </Route>
  )
)
