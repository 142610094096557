import { Test } from "~/__generated__/graphql"

import { useSafeMutation } from "~/common/useSafeMutation"
import { gql } from "~/__generated__"
import toast from "react-hot-toast"
import { Button } from "~/shadcn/ui/button"
import invariant from "tiny-invariant"

export const RescoreTestAlert = ({
  test,
}: {
  test: Pick<Test, "id" | "hasAiScoringUpdates" | "rescoring"> | null
}) => {
  const [rescoreTest, { loading }] = useSafeMutation(TEST_RESCORE_MUTATION)

  const handleClick = async () => {
    invariant(test)
    const { data, errors } = await rescoreTest({
      variables: {
        input: {
          testId: test.id,
        },
      },
    })

    if (!data?.testRescore?.test) {
      toast.error("Error scheduling rescoring.")
      if (errors) {
        console.log(errors)
      }
    } else {
      toast.success(`Test is being rescored.`)
    }
  }

  return (
    test?.hasAiScoringUpdates &&
    !test.rescoring && (
      <div className="rounded-lg bg-gray-F7F7F5 p-4 flex items-center justify-between">
        <div className="flex flex-col gap-2">
          <h3 className="text-gray-333 font-semibold">
            AI Scoring Prompts Have Changed
          </h3>
          <p className="text-sm text-gray-999">
            Would you like to re-run AI scoring for all candidates based on the
            latest updates?
          </p>
        </div>
        <div>
          <Button onClick={handleClick} disabled={loading}>
            Re-run Scoring
          </Button>
        </div>
      </div>
    )
  )
}

const TEST_RESCORE_MUTATION = gql(`
  mutation TestRescore ($input: TestRescoreInput!){
    testRescore(input: $input) {
      test {
        id
        hasAiScoringUpdates
        rescoring
      }
    }
  }
`)
