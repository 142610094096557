import { useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { gql } from "~/__generated__"
import {
  organizationTestsPath,
  organizationTestQuestionsPath,
} from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { Button } from "~/shadcn/ui/button"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { Form } from "~/shadcn/ui/form"
import { TextField } from "~/ui/forms/TextField"
import { TextareaField } from "~/ui/forms/TextareaField"
import { SelectField } from "~/ui/forms/SelectField"
import toast from "react-hot-toast"
import { displayErrors } from "~/common/validations"
import { useOrganizationId } from "~/common/useCurrentOrganization"
import {
  DisclaimerDialogForm,
  DisclaimerDialogStateType,
} from "~/tests/DisclaimerDialogForm"
import {
  Test_OrgTestLayoutFragment,
  OrgLayoutTestQuery,
  TestPublishStatusEnum,
} from "~/__generated__/graphql"
import { ApolloQueryResult } from "@apollo/client"
import { DisclaimerDestroyButton } from "~/tests/DisclaimerDestroyButton"
import PencilSquare from "~/images/icons/pencil-square.svg?react"
import EnvelopeOpen from "~/images/icons/envolope-open.svg?react"
import DevicePhoneMobile from "~/images/icons/device-phone-mobile.svg?react"
import Headphones from "~/images/icons/headphones.svg?react"
import VideoCamera from "~/images/icons/video-camera.svg?react"
import { HelpTooltip } from "~/ui/HelpTooltip"
import { PublishWarnOnIssues } from "~/tests/PublishStatusUI"

import { TestSettingToggle } from "~/tests/TestSettingToggle"

const formSchema = z.object({
  name: z.string().min(1, {
    message: "Required",
  }),
  description: z.string().optional(),
  welcomeNote: z.string().optional(),
  closingNotes: z.string().optional(),
  publishStatus: z.nativeEnum(TestPublishStatusEnum).optional(),
  estimatedTimeMinutes: z
    .literal("")
    .transform(() => null)
    .or(z.literal(undefined).transform(() => null))
    .or(
      z
        .string()
        .regex(/^[0-9]+$/, "Please enter a positive number")
        .nullable()
    ),
})

export const OverviewForm = ({
  test,
  refetchTest,
}: {
  test: Test_OrgTestLayoutFragment
  refetchTest: () => Promise<ApolloQueryResult<OrgLayoutTestQuery>>
}) => {
  const organizationId = useOrganizationId()
  const [action, setAction] = useState("continue")

  const [testUpdate, { loading }] = useSafeMutation(TEST_UPDATE_MUTATION)
  const navigate = useNavigate()

  const [dialogState, setDialogState] = useState<DisclaimerDialogStateType>({
    dialogOpen: false,
    testId: test.id,
    disclaimerId: null,
    disclaimerText: "",
  })
  const setDialogOpen = (open: boolean) => {
    setDialogState({ ...dialogState, dialogOpen: open })
  }
  // Maybe just grab this on close always?
  const onDialogSuccess = () => {
    refetchTest()
  }

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: test.name || "",
      description: test.description || "",
      welcomeNote: test.welcomeNote || "",
      closingNotes: test.closingNotes || "",
      publishStatus: test.publishStatus || TestPublishStatusEnum.Unpublished,
      estimatedTimeMinutes: test.estimatedTimeMinutes?.toString() || "",
    },
  })

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    if (!test) {
      return
    }
    const vars = {
      testId: test.id,
      name: values.name,
      description: values.description,
      welcomeNote: values.welcomeNote,
      closingNotes: values.closingNotes,
      publishStatus: values.publishStatus,
      estimatedTimeMinutes: values.estimatedTimeMinutes
        ? parseInt(values.estimatedTimeMinutes)
        : null,
    }
    const { data, errors } = await testUpdate({
      variables: {
        input: vars,
      },
    })

    if (errors) {
      displayErrors(errors, form.setError)
      console.log(errors)
    } else if (data?.testUpdate?.test) {
      toast.success("Test Updated")
      if (action === "draft") {
        navigate(organizationTestsPath({ organizationId }))
      } else {
        navigate(
          organizationTestQuestionsPath({
            organizationId,
            testId: test?.id,
          })
        )
      }
    } else {
      toast.error("Error updating test.")
    }
  }

  const [checkTestState, setCheckTestState] = useState(false)
  const formEl = useRef<HTMLFormElement>(null)

  const doSubmit = () => {
    formEl.current?.requestSubmit()
  }
  const maybeCheckTestState = (fromAction: string) => {
    setAction(fromAction)
    setTimeout(() => {
      if (
        test.publishStatus === TestPublishStatusEnum.Unpublished &&
        form.getValues().publishStatus === TestPublishStatusEnum.Published
      ) {
        setCheckTestState(true)
      } else {
        doSubmit()
      }
    })
  }

  return (
    <>
      <DisclaimerDialogForm
        testId={test.id}
        disclaimerId={dialogState.disclaimerId}
        disclaimerText={dialogState.disclaimerText}
        dialogOpen={dialogState.dialogOpen}
        setDialogOpen={setDialogOpen}
        onSuccess={onDialogSuccess}
      />
      <PublishWarnOnIssues
        test={test}
        onPublish={doSubmit}
        checkTestState={checkTestState}
        setCheckTestState={setCheckTestState}
      />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-4"
          ref={formEl}
        >
          <div className="grid grid-cols-12 gap-8">
            <div className="col-span-7 flex flex-col gap-4">
              <TextField
                control={form.control}
                name="name"
                label="Test Name"
                required
              />
              <SelectField
                control={form.control}
                name="publishStatus"
                label="Published Status"
                selectEntries={[
                  {
                    label: "Unpublished",
                    value: TestPublishStatusEnum.Unpublished,
                  },
                  {
                    label: "Published",
                    value: TestPublishStatusEnum.Published,
                  },
                ]}
              />
              <TextField
                control={form.control}
                name="estimatedTimeMinutes"
                label="Estimated Time Minutes"
              />
              <TextareaField
                control={form.control}
                name="description"
                label="Test Description"
                placeholder="Description goes here..."
              />
              <TextareaField
                control={form.control}
                name="welcomeNote"
                label="Welcome Note"
                placeholder="Welcome note goes here..."
              />
              <TextareaField
                control={form.control}
                name="closingNotes"
                label="Closing Notes"
                placeholder="Closing note goes here..."
                helpText="Closing note is what the test takers sees after they complete the test. You can put a thank you here or other note telling them when you will review their work."
              />

              <div className="flex flex-col gap-2">
                <label
                  htmlFor="add-disclaimer"
                  className="peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-gray-999 font-normal text-base flex gap-2 items-center"
                >
                  Test Disclaimers
                  <HelpTooltip className="max-w-64 text-gray-333">
                    If you need test takers to agree to any disclaimers or legal
                    terms, put them here and they will click through agree
                    before starting the test
                  </HelpTooltip>
                </label>
                <ol className="text-gray-999 text-base font-normal">
                  {test.disclaimers?.map((disclaimer) => {
                    return (
                      <li
                        key={disclaimer.id}
                        className="flex flex-row gap-2 justify-between items-center"
                      >
                        <div className="truncate">
                          {disclaimer.disclaimerText}
                        </div>
                        <div className="shrink flex flex-row gap-2 justify-end items-center">
                          <Button
                            name="edit-disclaimer"
                            className="px-0"
                            type="button"
                            variant="ghost"
                            onClick={() =>
                              setDialogState({
                                ...dialogState,
                                dialogOpen: true,
                                disclaimerText: disclaimer.disclaimerText,
                                disclaimerId: disclaimer?.id,
                              })
                            }
                          >
                            <PencilSquare className="h-6 w-6" />
                          </Button>
                          <DisclaimerDestroyButton
                            disclaimerId={disclaimer.id}
                            onDestroy={(_id: string) => refetchTest()}
                          />
                        </div>
                      </li>
                    )
                  })}
                </ol>
                <div>
                  <Button
                    name="add-disclaimer"
                    type="button"
                    onClick={() =>
                      setDialogState({
                        ...dialogState,
                        dialogOpen: true,
                        disclaimerText: "",
                        disclaimerId: null,
                      })
                    }
                  >
                    Add Test Disclaimer
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-span-5">
              <div className="flex flex-col gap-2">
                <span className="text-base font-normal text-gray-999">
                  Test Settings
                </span>
                <div className="flex flex-col divide-y divide-gray-E6E6E3 border border-gray-E6E6E3 rounded-lg">
                  <TestSettingToggle
                    id="require-audio"
                    title="Audio"
                    subTitle="Require voice, headphone, or speaker audio?"
                    fieldName="requireAudio"
                    icon={Headphones}
                    test={test}
                  />
                  <TestSettingToggle
                    id="require-video"
                    title="Video"
                    subTitle="Require video for this test?"
                    fieldName="requireVideo"
                    icon={VideoCamera}
                    test={test}
                  />
                  <TestSettingToggle
                    id="require-email-verification"
                    title="Email Verification"
                    subTitle="Require user verification via email?"
                    fieldName="requireEmailVerification"
                    icon={EnvelopeOpen}
                    test={test}
                  />
                  <TestSettingToggle
                    id="require-phone-verification"
                    title="Phone Verifications"
                    subTitle="Require a valid phone number?"
                    fieldName="requirePhoneVerification"
                    icon={DevicePhoneMobile}
                    test={test}
                  />
                </div>
              </div>
            </div>

            <div className="col-span-12 h-[1px] my-4 bg-gray-E3E2E0" />

            <div className="col-span-12 flex flex-row justify-between">
              <div className="flex flex-row gap-2">
                <Button
                  name="action"
                  className="btn"
                  disabled={loading}
                  type="button"
                  onClick={(_e) => {
                    maybeCheckTestState("continue")
                  }}
                >
                  Save & Continue
                </Button>

                <Button
                  name="action"
                  className="btn"
                  variant="outline"
                  disabled={loading}
                  type="button"
                  onClick={(_e) => {
                    maybeCheckTestState("draft")
                  }}
                >
                  Save Draft
                </Button>
              </div>
              <div>
                <Button
                  className="btn"
                  variant="ghost"
                  disabled={loading}
                  type="button"
                  onClick={() =>
                    navigate(
                      organizationTestsPath({
                        organizationId,
                      })
                    )
                  }
                >
                  Close & Cancel
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Form>
    </>
  )
}

gql(`
  fragment Test_OrgTestOverviewForm on Test {
    id
    name
    publishStatus
    description
    welcomeNote
    closingNotes
    estimatedTimeMinutes
  }
`)

const TEST_UPDATE_MUTATION = gql(`
  mutation TestUpdate($input: TestUpdateInput!) {
    testUpdate(input: $input) {
      test {
        ...Test_OrgTestOverviewForm
      }
    }
  }
`)
