import {
  useOrganizationId,
  useCurrentOrganization,
} from "~/common/useCurrentOrganization"
import { PageHeader } from "~/ui/PageHeader"
import { gql } from "~/__generated__"
import { useQuery } from "@apollo/client"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { Error } from "~/ui/Error"
import { Org_OrganizationUsersFragment } from "~/__generated__/graphql"
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHead,
  TableRow,
} from "~/ui/tables/Table"
import { OrganizationUserCreateButton } from "~/organizations/OrganizationUserCreateButton"
import { OrganizationUserDestroyButton } from "~/organizations/OrganizationUserDestroyButton"
import { usePagination } from "~/common/usePagination"
import { Pagination } from "~/ui/Pagination"
import { OrganizationLogoUpload } from "~/organizations/OrganizationLogoUpload"
import { SettingToggle } from "~/ui/forms/SettingToggle"
import { useSafeMutation } from "~/common/useSafeMutation"
import toast from "react-hot-toast"
import { Organization } from "~/__generated__/graphql"
import invariant from "tiny-invariant"

const OrganizationUsersTable = ({
  data,
}: {
  data: Org_OrganizationUsersFragment[]
}) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Email</TableHead>
          <TableHead className="text-right">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((orgUser) => (
          <TableRow key={orgUser.id}>
            <TableCell>{orgUser.user.email}</TableCell>
            <TableCell className="flex flex-row items-center gap-2 justify-end">
              <OrganizationUserDestroyButton
                organizationUserId={orgUser.id}
                email={orgUser.user.email}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const PER_PAGE = 10

export const OrganizationSettingsScreen = () => {
  const organizationId = useOrganizationId()
  const organization = useCurrentOrganization()
  invariant(organization, "Organization must be defined")

  const { after, paginate, page } = usePagination({ perPage: PER_PAGE })
  const { loading, data, error, refetch } = useQuery(
    ORGANIZATION_USERS_QUERY_DOCUMENT,
    {
      variables: { organizationId: organizationId, first: PER_PAGE, after },
    }
  )

  const handleRefetch = () => {
    refetch()
  }

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading tests." />

  return (
    <div className="container mx-auto flex flex-col gap-8 mt-8 pb-12">
      <PageHeader
        title={`${organization?.name} Users`}
        subhead="Manage organization users and settings."
        left={
          <OrganizationLogoUpload
            organizationId={organizationId}
            logoThumbUrl={organization?.logoThumbUrl}
          />
        }
      >
        <div>
          <OrganizationUserCreateButton
            organizationId={organizationId}
            onSuccess={handleRefetch}
          />
        </div>
      </PageHeader>
      <div className="grid grid-cols-3 gap-6">
        {data.organizationUsers ? (
          <div className="col-span-2">
            <OrganizationUsersTable data={data.organizationUsers.nodes} />
            {data.organizationUsers.pageCount &&
            data.organizationUsers.pageCount > 1 ? (
              <Pagination
                page={page}
                pageCount={data.organizationUsers.pageCount}
                paginate={paginate}
              />
            ) : null}
          </div>
        ) : null}
        <div className="col-span-1">
          <OrganizationSettingsBox organization={organization} />
        </div>
      </div>
    </div>
  )
}

const OrganizationSettingsBox = ({
  organization,
}: {
  organization: Pick<
    Organization,
    "id" | "sendCandidateStartedTestEmail" | "sendCandidateSubmittedTestEmail"
  >
}) => {
  const [testUpdate, { loading }] = useSafeMutation(
    ORGANZATION_SETTING_UPDATE_MUTATION
  )

  const handleCheckedChange = async (checked: boolean, fieldName: string) => {
    const { data, errors } = await testUpdate({
      variables: {
        input: {
          organizationId: organization.id,
          [fieldName]: checked,
        },
      },
    })

    if (errors) {
      toast.error("Error updating setting.")
      console.log(errors)
    } else if (data?.organizationUpdate?.organization) {
      toast.success(`Setting updated`)
    } else {
      toast.error("Error updating setting.")
    }
  }

  return (
    <div className="flex flex-col rounded-lg divide-y divide-y-gray-E6E6E3 border border-gray-E6E6E3">
      <div className="py-4 px-6 bg-gray-F7F7F5 flex flex-col gap-1">
        <h2 className="text-gray-333 font-semibold">Email Notifications</h2>
        <p className="text-gray-999 text-sm">
          Manage the email alerts that are sent to your Organization members.
        </p>
      </div>
      <div className="p-2 flex flex-col">
        <SettingToggle
          id="send-candidate-started-test-email"
          title="Started a Test"
          subTitle="Notify me via email when a candidate has started a test."
          checked={organization.sendCandidateStartedTestEmail}
          onCheckedChange={(checked: boolean) => {
            handleCheckedChange(checked, "sendCandidateStartedTestEmail")
          }}
          loading={loading}
        />
        <SettingToggle
          id="send-candidate-submitted-test-email"
          title="Submitted a Test"
          subTitle="Notify me via email when a candidate has submitted a test."
          checked={organization.sendCandidateSubmittedTestEmail}
          onCheckedChange={(checked: boolean) => {
            handleCheckedChange(checked, "sendCandidateSubmittedTestEmail")
          }}
          loading={loading}
        />
      </div>
    </div>
  )
}

gql(`
  fragment Org_OrganizationUsers on OrganizationUser {
    id
    user {
      id
      email
    }
  }
`)

const ORGANIZATION_USERS_QUERY_DOCUMENT = gql(`
  query OrganizationUsers($organizationId: ID!, $first: Int!, $after: String) {
    organizationUsers(organizationId: $organizationId, first: $first, after: $after) {
      pageCount(first: $first)
      nodes {
        ...Org_OrganizationUsers
      }
    }
  }
`)

const ORGANZATION_SETTING_UPDATE_MUTATION = gql(`
  mutation OrganizationSettingsUpdate($input: OrganizationUpdateInput!) {
    organizationUpdate(input: $input) {
      organization {
        id
        sendCandidateStartedTestEmail
        sendCandidateSubmittedTestEmail
      }
    }
  }
`)
