import { Button } from "~/shadcn/ui/button"
import { useFieldArray, UseFieldArrayRemove, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { TextField } from "~/ui/forms/TextField"
import { Form } from "~/shadcn/ui/form"

import {
  QuestionDifficultyEnum,
  QuestionGroup_FormFragment,
  QuestionTypeEnum,
} from "~/__generated__/graphql"
import { SelectField } from "~/ui/forms/SelectField"
import { useCallback, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { organizationTestQuestionsPath } from "~/common/paths"
import { useOrganizationId } from "~/common/useCurrentOrganization"
import { useOrganizationTest } from "~/common/useOrganizationTest"
import { Divider } from "~/ui/Divider"
import { useQuestionTypeChanged } from "./useQuestionTypeChanged"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import toast from "react-hot-toast"
import { QuestionSubsection } from "./QuestionSubsection"
import { FormPanel } from "./FormPanel"
import {
  divideIntoEqualNumbers,
  includesChoices,
  QUESTION_TYPE_NAMES,
} from "./utils"
import omit from "lodash.omit"
import { AdvancedToggle } from "./AdvancedToggle"
import { cn } from "~/common/shadcn-utils"
import { TagsSelect } from "./TagsSelect"

export const questionGroupFormSchema = z
  .object({
    id: z.string().optional(),
    name: z.string().min(1, {
      message: "Required",
    }),
    advancedSettingsOpen: z.boolean(),
    internalDescription: z.string(),
    questionType: z.nativeEnum(QuestionTypeEnum),
    frequency: z.string(),
    frequencyPercentage: z.coerce.number().lte(100).gte(0),

    difficulty: z.nativeEnum(QuestionDifficultyEnum).nullable(),
    timeLimitSeconds: z
      .string()
      .regex(/^[0-9]*(:[0-5][0-9])?$/, "must be a whole number or MM:SS"),
    expectedTimeSeconds: z
      .string()
      .regex(/^[0-9]*(:[0-5][0-9])?$/, "must be a whole number or MM:SS"),

    tags: z.array(z.string()),

    questions: z
      .array(
        z.object({
          id: z.string().optional(),
          uiOpened: z.boolean(),
          advancedSettingsOpen: z.boolean(),
          questionCopy: z.string().min(1, {
            message: "Required",
          }),
          frequencyPercentage: z.number().lte(100).gte(0),
          scoringPrompt: z.string(),
          exampleGoodAnswer: z.string(),
          exampleBadAnswer: z.string(),

          questionChoices: z.array(
            z.object({
              id: z.string().optional(),
              choiceCopy: z.string().min(1, {
                message: "Required",
              }),
              isCorrect: z.boolean(),
            })
          ),
        })
      )
      .nonempty(),
  })
  .superRefine((data, ctx) => {
    const totalPercentage = data.questions.reduce(
      (acc, question) => (acc += question.frequencyPercentage),
      0
    )

    if (totalPercentage !== 100) {
      ctx.addIssue({
        path: [`questions.0.frequencyPercentage`],
        message: "Percentages must add up to 100%",
        code: "custom",
      })
    }
  })

const QUESTION_DEFAULTS = {
  uiOpened: true,
  questionCopy: "",
  frequencyPercentage: 100,
  questionChoices: [],
  scoringPrompt: "",
  exampleGoodAnswer: "",
  exampleBadAnswer: "",
  advancedSettingsOpen: false,
}

const timeToSeconds = (value: string): number | undefined => {
  if (value.length > 0) {
    const parts = value.split(":")
    if (parts.length === 2) {
      return parseInt(parts[0], 10) * 60 + parseInt(parts[1], 10)
    } else {
      return parseInt(value, 10)
    }
  }
}

const secondsToTime = (value: number | null | undefined) => {
  if (value === null || value === undefined) return ""

  if (value <= 90) {
    return value.toString()
  }

  const minutes = Math.floor(value / 60)
  const seconds = Math.floor(value % 60)

  return `${minutes.toString()}:${seconds.toString().padStart(2, "0")}`
}

export const QuestionGroupForm = ({
  questionGroup,
}: {
  questionGroup?: QuestionGroup_FormFragment | null
}) => {
  const organizationId = useOrganizationId()
  const { testId, test } = useOrganizationTest()
  const navigate = useNavigate()

  const form = useForm<z.infer<typeof questionGroupFormSchema>>({
    reValidateMode: "onChange",
    resolver: zodResolver(questionGroupFormSchema),
    defaultValues: {
      name: questionGroup?.name || "",
      difficulty: questionGroup?.difficulty || null,
      advancedSettingsOpen: false,
      frequency: questionGroup?.frequencyPercentage
        ? questionGroup.frequencyPercentage === 100
          ? "always"
          : questionGroup.frequencyPercentage === 0
          ? "never"
          : "percentage"
        : "always", // default to always
      frequencyPercentage: questionGroup?.frequencyPercentage || 100,
      timeLimitSeconds: secondsToTime(questionGroup?.timeLimitSeconds) || "",
      expectedTimeSeconds:
        secondsToTime(questionGroup?.expectedTimeSeconds) || "",
      tags: questionGroup?.tags.map((tag) => tag.name) || [],
      questionType:
        questionGroup?.questionType || QuestionTypeEnum.TextResponse,
      internalDescription: questionGroup?.internalDescription || "",
      questions:
        questionGroup?.questions && questionGroup.questions.length > 0
          ? questionGroup.questions.map((question) => ({
              id: question.id || undefined,
              uiOpened: questionGroup.questions.length === 1,
              advancedSettingsOpen: false,
              questionCopy: question.questionCopy || "",
              frequencyPercentage: question.frequencyPercentage || 100,
              scoringPrompt: question.scoringPrompt || "",
              exampleGoodAnswer: question.exampleGoodAnswer || "",
              exampleBadAnswer: question.exampleBadAnswer || "",
              questionChoices: question.questionChoices.map((choice) => ({
                id: choice.id || undefined,
                choiceCopy: choice.choiceCopy || "",
                isCorrect: choice.isCorrect || false,
              })),
            }))
          : [QUESTION_DEFAULTS],
    },
  })

  // This clears values out of the form that no longer apply once
  const onQuestionTypeChange = useCallback(
    (questionType: QuestionTypeEnum) => {
      if (
        questionType === QuestionTypeEnum.TextResponse ||
        questionType === QuestionTypeEnum.VideoResponse
      ) {
        form.getValues("questions").forEach((q, index) => {
          form.setValue(`questions.${index}.questionChoices`, [])
        })
      } else {
        form.getValues("questions").forEach((q, index) => {
          if (q.questionChoices.length > 0) {
            // if there are choices already, set their values to false
            form.setValue(
              `questions.${index}.questionChoices`,
              q.questionChoices.map((c) => ({ ...c, isCorrect: false }))
            )
          } else {
            // else add 1 default
            form.setValue(`questions.${index}.questionChoices`, [
              {
                choiceCopy: "",
                isCorrect: false,
              },
            ])
          }
        })
      }
    },
    [form]
  )
  useQuestionTypeChanged(form.watch("questionType"), onQuestionTypeChange)

  const onSelectedTagsChange = useCallback(
    (tags: string[]) => {
      form.setValue("tags", tags)
    },
    [form]
  )

  const {
    fields,
    append,
    remove: _removeQuestion,
  } = useFieldArray({
    control: form.control,
    name: "questions",
  })

  const reassignPercentages = () => {
    const questions = form.watch("questions")
    const newPercentages = divideIntoEqualNumbers(questions.length)
    questions.forEach((_, index) => {
      form.setValue(
        `questions.${index}.frequencyPercentage`,
        newPercentages[index]
      )
    })
  }

  const appendQuestion: typeof append = (value) => {
    append(value)
    reassignPercentages()
  }

  const removeQuestion: UseFieldArrayRemove = (index) => {
    _removeQuestion(index)
    setTimeout(reassignPercentages, 50)
  }

  const [questionGroupUpdate, { loading }] = useSafeMutation(
    QUESTION_GROUP_UPSERT_MUTATION
  )
  const onSubmit = async (values: z.infer<typeof questionGroupFormSchema>) => {
    const { errors } = await questionGroupUpdate({
      variables: {
        input: {
          testId,
          questionGroupId: questionGroup?.id,
          input: {
            ...omit(values, [
              "advancedSettingsOpen",
              "frequency",
              "frequencyPercentage",
            ]),
            frequencyPercentage: parseInt(`${values.frequencyPercentage}`, 10),
            timeLimitSeconds: timeToSeconds(values.timeLimitSeconds),
            expectedTimeSeconds: timeToSeconds(values.expectedTimeSeconds),
            questions: values.questions.map((q) =>
              omit(q, ["uiOpened", "advancedSettingsOpen"])
            ),
          },
        },
      },
    })

    if (errors) {
      displayErrors(errors, form.setError)
      console.log(errors)
    } else {
      toast.success("Question group saved!")
      navigate(organizationTestQuestionsPath({ organizationId, testId }))
    }
  }

  const advancedSettingsOpen = form.watch("advancedSettingsOpen")
  const frequency = form.watch("frequency")
  const showFrequencyPercentage = frequency === "percentage"

  useEffect(() => {
    if (questionGroup) {
      if (questionGroup.frequencyPercentage === 100) {
        form.setValue("frequency", "always")
      } else if (questionGroup.frequencyPercentage === 0) {
        form.setValue("frequency", "never")
      } else {
        form.setValue("frequency", "percentage")
      }
    }
  }, [questionGroup, form])

  useEffect(() => {
    if (frequency === "always") {
      form.setValue("frequencyPercentage", 100)
    } else if (frequency === "never") {
      form.setValue("frequencyPercentage", 0)
    } else {
      form.setValue(
        "frequencyPercentage",
        questionGroup?.frequencyPercentage || 50
      )
    }
  }, [frequency, form, questionGroup])

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col pb-12"
      >
        <FormPanel className="gap-4 mb-8">
          <SelectField
            control={form.control}
            name="questionType"
            label="Question Type"
            selectEntries={[
              {
                label: QUESTION_TYPE_NAMES[QuestionTypeEnum.TextResponse],
                value: QuestionTypeEnum.TextResponse,
              },
              ...(test?.requireAudio
                ? [
                    {
                      label:
                        QUESTION_TYPE_NAMES[QuestionTypeEnum.VideoResponse],
                      value: QuestionTypeEnum.VideoResponse,
                    },
                  ]
                : []),
              {
                label: QUESTION_TYPE_NAMES[QuestionTypeEnum.SingleChoice],
                value: QuestionTypeEnum.SingleChoice,
              },
              {
                label: QUESTION_TYPE_NAMES[QuestionTypeEnum.MultipleChoice],
                value: QuestionTypeEnum.MultipleChoice,
              },
            ]}
          />

          <div className="grid grid-cols-6 gap-6">
            <TextField
              control={form.control}
              name="name"
              label="Internal Question Group Name"
              placeholder="Name this question group"
              containerClassName="col-span-3"
            />
            <SelectField
              control={form.control}
              name="frequency"
              label="Frequency"
              selectEntries={[
                {
                  label: "Always (Default)",
                  value: "always",
                },
                { label: "Never", value: "never" },
                {
                  label: "Percentage",
                  value: "percentage",
                },
              ]}
              containerClassName={cn(
                showFrequencyPercentage ? "col-span-2" : "col-span-3"
              )}
            />
            {showFrequencyPercentage && (
              <TextField
                control={form.control}
                name="frequencyPercentage"
                label="Frequency Percentage"
                type="number"
              />
            )}
            <TagsSelect
              containerClassName="col-span-2"
              selectedTags={form.watch("tags") as string[]}
              onSelectedTagsChange={onSelectedTagsChange}
            />

            <TextField
              containerClassName="col-span-2"
              control={form.control}
              name="timeLimitSeconds"
              label="Time Limit (Sec or MM:SS)"
            />

            <TextField
              containerClassName="col-span-2"
              control={form.control}
              name="expectedTimeSeconds"
              label="Expected Time (Sec or MM:SS)"
            />
          </div>

          {advancedSettingsOpen && (
            <div className="mt-4">
              <TextField
                control={form.control}
                name="internalDescription"
                label="Group Description (Internal Only)"
              />

              <div className="grid grid-cols-3 gap-6">
                <SelectField
                  control={form.control}
                  name="difficulty"
                  label="Difficulty"
                  placeholder="Select a difficulty"
                  selectEntries={[
                    { label: "Easy", value: QuestionDifficultyEnum.Easy },
                    { label: "Medium", value: QuestionDifficultyEnum.Medium },
                    {
                      label: "Difficult",
                      value: QuestionDifficultyEnum.Difficult,
                    },
                  ]}
                  containerClassName="flex-1"
                />
              </div>
            </div>
          )}

          <AdvancedToggle
            text="Advanced Settings (Description & Difficulty)"
            open={advancedSettingsOpen}
            onClick={() =>
              form.setValue("advancedSettingsOpen", !advancedSettingsOpen)
            }
          />
        </FormPanel>

        <div className="mb-6">Question Variants</div>

        {fields.map((field, index) => (
          <QuestionSubsection
            key={field.id}
            index={index}
            removeQuestion={() => removeQuestion(index)}
            canRemove={fields.length > 1}
            userGoodAnswers={
              questionGroup?.questions?.at(index)?.userGoodAnswers
            }
            userBadAnswers={questionGroup?.questions?.at(index)?.userBadAnswers}
          />
        ))}

        <div className="flex justify-end">
          <Button
            type="button"
            variant="ghost"
            onClick={() =>
              appendQuestion({
                ...QUESTION_DEFAULTS,
                questionChoices: includesChoices(form.watch("questionType"))
                  ? [
                      {
                        choiceCopy: "",
                        isCorrect: false,
                      },
                    ]
                  : [],
              })
            }
          >
            Add Another Question Variant
          </Button>
        </div>

        <Divider className="my-10" />

        <div className="flex items-center">
          <Button
            type="submit"
            disabled={loading}
            onClick={() => console.log("errors:", form.formState.errors)}
          >
            Save Question
          </Button>
          <Link
            to={organizationTestQuestionsPath({ organizationId, testId })}
            className="ml-auto text-gray-999 font-medium"
          >
            Close & Cancel
          </Link>
        </div>
      </form>
    </Form>
  )
}

export const QUESTION_GROUP_UPSERT_MUTATION = gql(`
  mutation QuestionGroupCreateOrUpdate($input: QuestionGroupCreateOrUpdateInput!) {
    questionGroupCreateOrUpdate(input: $input) {
      questionGroup {
        ...QuestionGroup_Form
      }
    }
  }
`)
