import { ReactNode } from "react"
import {
  Test_OrgTestLayoutFragment,
  TestAccessEnum,
  TestPublishStatusEnum,
} from "~/__generated__/graphql"
import { useOrganizationId } from "~/common/useCurrentOrganization"
import { format, parseISO } from "date-fns"
import { Navigation } from "~/organizations/Navigation"
import { Button } from "~/shadcn/ui/button"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import toast from "react-hot-toast"
import { useNavigate, Link } from "react-router-dom"
import {
  organizationTestsPath,
  organizationTestAccessPath,
} from "~/common/paths"
import { ConfirmDialog } from "~/ui/Confirm"
import DotIcon from "~/images/icons/dot.svg?react"

const TEST_ACCESS_NAMES = {
  [TestAccessEnum.Public]: "Public",
  [TestAccessEnum.PrivateUrl]: "Share Link",
  [TestAccessEnum.EmailInvitation]: "Invite Only",
}

const PublishedLabel = ({
  testId,
  organizationId,
  publishStatus,
}: {
  testId: string
  organizationId: string
  publishStatus: TestPublishStatusEnum
}) => {
  const published = publishStatus === TestPublishStatusEnum.Published
  const dotColor = published ? "text-[#34C759]" : "text-[#cc0000]"

  if (published) {
    return (
      <div className="flex gap-2 text-sm items-center text-color-333">
        <DotIcon className={dotColor} />
        Published
      </div>
    )
  } else {
    return (
      <Link
        to={organizationTestAccessPath({ organizationId, testId })}
        className="flex gap-2 text-sm items-center text-color-333 text-primary hover:underline underline-offset-4"
      >
        <DotIcon className={dotColor} />
        Unublished
      </Link>
    )
  }
}

export const OrganizationTestLayout = ({
  heading,
  subhead,
  test,
  children,
}: {
  heading: string
  subhead?: string | ReactNode
  test: Test_OrgTestLayoutFragment | null
  children: ReactNode
}) => {
  const organizationId = useOrganizationId()
  const testId = test?.id || ""
  const navigate = useNavigate()

  const [testArchive, { loading: loadingTestArchive }] = useSafeMutation(
    TEST_ARCHIVE_MUTATION
  )
  const archive = async () => {
    if (!test) return
    const { data, errors } = await testArchive({
      variables: {
        input: {
          testId: test.id,
          archive: true,
        },
      },
    })

    if (errors) {
      toast.error("Error archiving test.")
      console.log(errors)
    } else if (data?.testArchive?.test) {
      toast.success("Test has been archived.")
      navigate(organizationTestsPath({ organizationId }))
    } else {
      toast.error("Error archiving test.")
    }
  }

  return (
    <>
      <Navigation organizationId={organizationId} testId={testId} />
      <div className="container mx-auto mt-8">
        <div className="flex items-center">
          <div>
            <div className="text-gray-333 text-2xl font-medium">{heading}</div>
            {subhead && <div className="text-gray-999">{subhead}</div>}
          </div>
          {test && (
            <div className="ml-auto text-right">
              <div className="text-gray-333 text-right font-medium">
                {test.name}
              </div>
              <div className="text-gray-999 text-sm text-right">
                Updated{" "}
                {format(parseISO(test.updatedAt), "MMM d, yyyy - h:mm aa")}
              </div>
              <div className="flex gap-6 items-center pt-2 justify-end">
                <PublishedLabel
                  organizationId={organizationId}
                  testId={test.id}
                  publishStatus={test.publishStatus}
                />

                <Link
                  to={organizationTestAccessPath({ organizationId, testId })}
                  className="text-sm text-primary hover:underline underline-offset-4"
                >
                  {TEST_ACCESS_NAMES[test.access]}
                </Link>

                <ConfirmDialog
                  text={`Archive "${test.name}"?`}
                  onSuccess={archive}
                >
                  <Button
                    variant="link"
                    size="link"
                    disabled={loadingTestArchive}
                    className="font-normal text-sm text-primary"
                  >
                    Archive Test
                  </Button>
                </ConfirmDialog>
              </div>
            </div>
          )}
        </div>

        <div className="bg-gray-E6E6E3 h-[1px] mt-4 mb-12" />

        {children}

        <div className="py-4" />
      </div>
    </>
  )
}

const TEST_ARCHIVE_MUTATION = gql(`
  mutation TestArchive($input: TestArchiveInput!) {
    testArchive(input: $input) {
      test {
        id
        discardedAt
      }
    }
  }
`)
