import { humanizeSecondsToMinutes } from "~/common/dates"
import { Test } from "~/__generated__/graphql"

export const TestTime = ({
  test,
}: {
  test: Pick<Test, "expectedTimeSeconds" | "estimatedTimeMinutes">
}) => {
  return (
    <>
      {test.estimatedTimeMinutes
        ? `${test.estimatedTimeMinutes} minutes`
        : test.expectedTimeSeconds &&
          humanizeSecondsToMinutes(test.expectedTimeSeconds)}
    </>
  )
}
