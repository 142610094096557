import { useState } from "react"
import { Button } from "~/shadcn/ui/button"
import { useSafeMutation } from "~/common/useSafeMutation"
import { gql } from "@apollo/client"
import toast from "react-hot-toast"
import { TextareaField } from "~/ui/forms/TextareaField"
import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { Form } from "~/shadcn/ui/form"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "~/shadcn/ui/dialog"
import { Mail } from "lucide-react"

const formSchema = z.object({
  message: z.string().min(1, {
    message: "Required",
  }),
})

export const EmailCandidateDialog = ({
  candidateTestId,
}: {
  candidateTestId: string
}) => {
  const [sendEmail] = useSafeMutation(SEND_CANDIDATE_EMAIL_MUTATION)
  const [dialogOpen, setDialogOpen] = useState(false)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  })

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const { data, errors } = await sendEmail({
      variables: {
        input: {
          candidateTestId,
          ...values,
        },
      },
    })

    if (!data?.sendCandidateEmail?.sent) {
      toast.error("Error sending email.")
      if (errors) {
        console.log(errors)
      }
    } else {
      toast.success("Email sent to candidate.")
      form.setValue("message", "")
      setDialogOpen(false)
    }
  }

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild={true}>
        <Button
          variant="outline"
          size="sm"
          onClick={() => setDialogOpen(true)}
          className="gap-2 text-gray-333"
        >
          <Mail size={16} />
          Message Candidate
        </Button>
      </DialogTrigger>
      <DialogContent className="lg:max-w-3xl">
        <DialogHeader>
          <DialogTitle className="text-xl font-medium text-gray-333 leading-normal tracking-normal">
            Email Candidate
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            <div className="flex flex-col gap-2">
              <TextareaField
                name="message"
                label="Your Message"
                control={form.control}
                inputClassName="h-48"
                required
              />
              <p className="text-sm text-gray-999">
                This candidate will receive an email with you CC’d
              </p>
            </div>
            <div className="flex items-center gap-6 justify-end">
              <div className="flex gap-4">
                <Button
                  type="button"
                  variant="ghost"
                  onClick={() => {
                    setDialogOpen(false)
                  }}
                  disabled={form.formState.isSubmitting}
                >
                  Cancel
                </Button>
                <Button type="submit" disabled={form.formState.isSubmitting}>
                  Send
                </Button>
              </div>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

const SEND_CANDIDATE_EMAIL_MUTATION = gql`
  mutation SendCandidateEmail($input: SendCandidateEmailInput!) {
    sendCandidateEmail(input: $input) {
      sent
    }
  }
`
