import { useQuery } from "@apollo/client"
import { gql } from "~/__generated__"

type UsePromotionalTestsProps = {
  first?: number
  after?: string
}

export const usePromotionalTests = ({
  first,
  after,
}: UsePromotionalTestsProps) => {
  const { data, loading, error } = useQuery(PROMOTIONAL_TESTS_QUERY_DOCUMENT, {
    variables: { first: first || 20, after },
  })

  const promotionalTests =
    data?.promotionalTests?.edges.map((edge) => edge.node) || []

  return {
    promotionalTests,
    loading,
    error,
    totalCount: data?.promotionalTests?.totalCount,
    pageCount: data?.promotionalTests?.pageCount,
    pageInfo: data?.promotionalTests?.pageInfo,
  }
}

gql(`
  fragment Test_PromotionalTestTable on Test {
    id
    name
    expectedTimeSeconds
    estimatedTimeMinutes

    organization {
      name
    }
  }
`)

const PROMOTIONAL_TESTS_QUERY_DOCUMENT = gql(`
  query promotionalTests($first: Int!, $after: String) {
    promotionalTests(first: $first, after: $after) {
      totalCount
      pageCount(first: $first)
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...Test_PromotionalTestTable
        }
      }
    }
  }
`)
