import { useState } from "react"
import { Button } from "~/shadcn/ui/button"
import {
  QuestionResponse,
  AiExampleEnum,
  QuestionTypeEnum,
} from "~/__generated__/graphql"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogTrigger,
} from "~/shadcn/ui/dialog"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { TextareaField } from "~/ui/forms/TextareaField"
import { SelectField } from "~/ui/forms/SelectField"
import toast from "react-hot-toast"
import { displayErrors } from "~/common/validations"
import { cn } from "~/common/shadcn-utils"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/shadcn/ui/form"
import { Checkbox } from "~/shadcn/ui/checkbox"
import { Control, FieldPath, FieldValues } from "react-hook-form"

// const SCALE = [4, 12, 20, 28, 36, 44, 52, 60, 68, 76, 84, 92, 100]
export const SCORE_SCALE = [
  { label: "A+", value: "100" },
  { label: "A", value: "92" },
  { label: "A-", value: "84" },
  { label: "B+", value: "76" },
  { label: "B", value: "68" },
  { label: "B-", value: "60" },
  { label: "C+", value: "52" },
  { label: "C", value: "44" },
  { label: "C-", value: "36" },
  { label: "D+", value: "28" },
  { label: "D", value: "20" },
  { label: "D-", value: "12" },
  { label: "F", value: "4" },
]
export const scoreValue = (score: number | string | undefined | null) => {
  if (score === undefined || score === null) {
    return ""
  }
  // Handle old scores that don't map to a letter
  return Math.abs(Math.round((Number(score) - 4) / 8.0) * 8 + 4)
}
export const scoreLabel = (score: number | string | undefined | null) => {
  if (score === undefined || score === null) {
    return ""
  }
  const value = scoreValue(score)
  return SCORE_SCALE.find((ss) => ss.value === value.toString())?.label
}

const formSchema = z.object({
  manualScore: z
    .literal("")
    .transform(() => null)
    .or(z.literal(undefined).transform(() => null))
    .or(
      z
        .string()
        .regex(
          /^[0-9]$|^[1-9][0-9]$|^(100)$/,
          "Please enter a number between 0 and 100"
        )
        .nullable()
    ),
  manualScoreNote: z.string().optional(),
  aiExample: z.nativeEnum(AiExampleEnum).optional(),
})

type PickedQuestionResponse = Pick<
  QuestionResponse,
  "id" | "manualScore" | "manualScoreNote" | "aiExample"
>

const useManualScoreData = (questionResponse: PickedQuestionResponse) => {
  const [testUpdate, { loading }] = useSafeMutation(QUESTION_RESPONSE_MUTATION)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      manualScore: scoreValue(questionResponse.manualScore).toString() || "",
      manualScoreNote: questionResponse.manualScoreNote || "",
      aiExample: questionResponse.aiExample || AiExampleEnum.None,
    },
  })

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const vars = {
      questionResponseId: questionResponse.id,
      manualScore: values.manualScore ? parseInt(values.manualScore) : null,
      manualScoreNote: values.manualScoreNote,
      aiExample: values.aiExample,
    }
    const { data, errors } = await testUpdate({
      variables: {
        input: vars,
      },
    })

    const success = !!data?.questionResponseUpdate?.questionResponse || !errors

    if (errors) {
      displayErrors(errors, form.setError)
      console.log(errors)
    }

    return success
  }

  return [form, onSubmit, loading] as const
}

const AiExampleField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  className,
}: {
  control: Control<TFieldValues> | undefined
  name: TName
  className?: string
}) => {
  return (
    <div className={cn("flex flex-col gap-1", className)}>
      <FormField
        control={control}
        name={name}
        render={({ field }) => (
          <>
            <FormItem className="flex items-center space-x-3 space-y-0">
              <FormControl>
                <Checkbox
                  className="bg-white"
                  checked={field.value?.includes("GOOD")}
                  onCheckedChange={(checked) => {
                    return checked
                      ? field.onChange("GOOD")
                      : field.onChange("NONE")
                  }}
                />
              </FormControl>
              <FormLabel className="font-normal">
                Use this response as a <strong>good example</strong> for AI
                scoring.
              </FormLabel>
            </FormItem>
            <FormItem className="flex items-center space-x-3 space-y-0">
              <FormControl>
                <Checkbox
                  className="bg-white"
                  checked={field.value?.includes("BAD")}
                  onCheckedChange={(checked) => {
                    return checked
                      ? field.onChange("BAD")
                      : field.onChange("NONE")
                  }}
                />
              </FormControl>
              <FormLabel className="font-normal">
                Use this response as a <strong>bad example</strong> for AI
                scoring.
              </FormLabel>
            </FormItem>
            <FormMessage />
          </>
        )}
      />
    </div>
  )
}

export const ManualScoreDialog = ({
  questionResponse,
  questionType,
}: {
  questionResponse: PickedQuestionResponse
  questionType: QuestionTypeEnum
}) => {
  return (
    <>
      {questionResponse.manualScore !== null &&
      questionResponse !== undefined ? (
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-2 justify-between">
            <span>{questionResponse.manualScore}%</span>
            <ManualScoreDialogForm
              text="Update"
              questionResponse={questionResponse}
              questionType={questionType}
            />
          </div>
          {questionResponse.manualScoreNote &&
            questionResponse.manualScoreNote.length > 0 && (
              <div>{questionResponse.manualScoreNote}</div>
            )}
        </div>
      ) : (
        <ManualScoreDialogForm
          text="Add Manual Score"
          questionResponse={questionResponse}
          questionType={questionType}
        />
      )}
    </>
  )
}

export const ManualScoreDialogForm = ({
  text,
  questionResponse,
  questionType,
  children,
}: {
  text: string
  questionResponse: PickedQuestionResponse
  questionType: QuestionTypeEnum
  children?: React.ReactNode
}) => {
  const [dialogOpen, setDialogOpen] = useState(false)

  const [form, doSubmit, loading] = useManualScoreData(questionResponse)

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const success = await doSubmit(values)
    if (success) {
      toast.success("Manual Score Updated")
      setDialogOpen(false)
    } else {
      toast.error("Error updating manual score.")
    }
  }

  const showAiExample = [
    QuestionTypeEnum.VideoResponse,
    QuestionTypeEnum.TextResponse,
  ].includes(questionType)

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild={true}>
        {children || <button className="text-primary">{text}</button>}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-xl font-medium text-gray-333 leading-normal tracking-normal">
            Manually Score an Answer
          </DialogTitle>
          <DialogDescription className="text-xl font-medium text-gray-999">
            How did the candidate do on this question?
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            className="flex flex-col gap-6"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <SelectField
              control={form.control}
              name="manualScore"
              label="Manual Score"
              selectEntries={SCORE_SCALE}
              required
            />
            <TextareaField
              inputClassName="h-24"
              control={form.control}
              name="manualScoreNote"
              label="Score Notes"
              placeholder="Type your score notes here..."
            />
            {showAiExample && (
              <AiExampleField control={form.control} name="aiExample" />
            )}
            <div className="bg-gray-E6E6E3 h-[1px]"></div>
            <div className="flex flex-row gap-2 items-center">
              <Button type="submit" disabled={loading}>
                Save Score
              </Button>
              <Button
                type="button"
                onClick={() => setDialogOpen(false)}
                variant="outline-light"
              >
                Close & Cancel
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export const ManualScoreDrawer = ({
  questionResponse,
  show,
  questionType,
}: {
  questionResponse: PickedQuestionResponse
  show: boolean
  questionType: QuestionTypeEnum
}) => {
  const [form, doSubmit, loading] = useManualScoreData(questionResponse)

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const success = await doSubmit(values)
    if (success) {
      toast.success("Manual Score Updated")
    } else {
      toast.error("Error updating manual score.")
    }
  }

  const showAiExample = [
    QuestionTypeEnum.VideoResponse,
    QuestionTypeEnum.TextResponse,
  ].includes(questionType)

  const showClasses = show ? "opacity-100 max-h-[1000px]" : "max-h-0 opacity-0"

  return (
    <Form {...form}>
      <form
        className={cn("transition-all ease-in-out duration-250", showClasses)}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="grid grid-cols-3 bg-gray-F7F7F5 p-6 gap-6">
          <SelectField
            control={form.control}
            name="manualScore"
            label="Manual Score"
            selectEntries={SCORE_SCALE}
            required
          />
          <TextareaField
            containerClassName="flex flex-col flex-grow col-span-3"
            inputClassName="flex-grow"
            control={form.control}
            name="manualScoreNote"
            label="Score Notes"
            placeholder="Type your score notes here..."
          />
          {showAiExample && (
            <AiExampleField
              control={form.control}
              name="aiExample"
              className="col-span-2"
            />
          )}
          <Button type="submit" disabled={loading} size="sm" className="w-full">
            Update Score
          </Button>
        </div>
      </form>
    </Form>
  )
}

export const QUESTION_RESPONSE_MUTATION = gql(`
  mutation QuestionResponseUpdate($input: QuestionResponseUpdateInput!) {
    questionResponseUpdate(input: $input) {
      questionResponse {
        id
        manualScore
        manualScoreNote
        aiExample
      }
    }
  }
`)
