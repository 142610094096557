import { useSafeMutation } from "~/common/useSafeMutation"
import { gql } from "~/__generated__"
import toast from "react-hot-toast"
import { ConfirmDialog } from "~/ui/Confirm"
import { useOrganizationId } from "~/common/useCurrentOrganization"

export const ExportCsvLink = ({ testId }: { testId?: string }) => {
  const organizationId = useOrganizationId()

  const [exec] = useSafeMutation(QUESTION_GROUP_ARCHIVE_MUTATION)

  const handleConfirm = async () => {
    const { errors } = await exec({
      variables: {
        input: {
          organizationId,
          testId,
        },
      },
    })

    if (errors) {
      toast.error("Error sending CSV export.")
      console.log(errors)
    } else {
      toast.success("The CSV Export is on the way to your email!")
    }
  }

  return (
    <ConfirmDialog
      text={"We'll send you a CSV export via email."}
      onSuccess={() => handleConfirm()}
      confirmText="Send CSV"
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a href="#" className="text-xs hover:underline">
        Export CSV
      </a>
    </ConfirmDialog>
  )
}

const QUESTION_GROUP_ARCHIVE_MUTATION = gql(`
  mutation CandidateCsvRequest($input: CandidateCsvRequestInput!) {
    candidateCsvRequest(input: $input) {
      success
    }
  }
`)
