import { OrganizationTestLayout } from "~/layouts/OrganizationTestLayout"
import { useOrganizationTest } from "~/common/useOrganizationTest"
import { gql } from "~/__generated__"
import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { QuestionGroupForm } from "~/questions/QuestionGroupForm/QuestionGroupForm"

export const OrganizationQuestionGroupEditScreen = () => {
  const { test } = useOrganizationTest()
  const { questionGroupId } = useParams()
  invariant(questionGroupId)

  const questionGroupResult = useQuery(QUESTION_GROUP_QUERY_DOCUMENT, {
    variables: { questionGroupId },
  })
  const questionGroup = questionGroupResult.data?.questionGroup || null

  return (
    <OrganizationTestLayout test={test} heading="Add a Question">
      <div>
        {questionGroup && <QuestionGroupForm questionGroup={questionGroup} />}
      </div>
    </OrganizationTestLayout>
  )
}

gql(`
  fragment QuestionGroup_Form_UserAnswers on QuestionResponse {
    id
    textResponse
    updatedAt
    questionAttempts {
      transcript
    }
    candidateTest {
      user {
        name
      }
    }
  }
`)

gql(`
  fragment QuestionGroup_Form on QuestionGroup {
    id
    name
    internalDescription
    questionType
    frequencyPercentage

    difficulty
    timeLimitSeconds
    expectedTimeSeconds

    tags {
      id
      name
    }

    questions {
      id
      questionCopy
      frequencyPercentage
      scoringPrompt
      exampleGoodAnswer
      exampleBadAnswer

      userGoodAnswers {
        ...QuestionGroup_Form_UserAnswers
      }
      userBadAnswers {
        ...QuestionGroup_Form_UserAnswers
      }

      questionChoices {
        id
        choiceCopy
        isCorrect
      }
    }
  }
`)

const QUESTION_GROUP_QUERY_DOCUMENT = gql(`
  query QuestionGroup($questionGroupId: ID!) {
    questionGroup(questionGroupId: $questionGroupId) {
      ...QuestionGroup_Form
    }
  }
`)
