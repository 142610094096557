import { useState, useEffect } from "react"
import { Button } from "~/shadcn/ui/button"
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogClose,
  DialogFooter,
  DialogPortal,
} from "~/shadcn/ui/dialog"

export const ConfirmDialog = ({
  text,
  onSuccess,
  open,
  onOpenChange,
  children,
  confirmText = "Confirm",
}: {
  text: string
  onSuccess: () => Promise<void>
  open?: boolean
  onOpenChange?: (val: boolean) => void
  children?: React.ReactNode
  confirmText?: string
}) => {
  const [internalOpen, setInternalOpen] = useState(open || false)

  useEffect(() => {
    if (typeof open === "boolean") {
      setInternalOpen(open as boolean)
    }
  }, [open])

  const onInternalOpenChange = (val: boolean) => {
    onOpenChange ? onOpenChange(val) : setInternalOpen(val)
  }

  const doSuccess = async () => {
    onSuccess()
    onInternalOpenChange(false)
  }

  return (
    <Dialog open={internalOpen} onOpenChange={onInternalOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogPortal>
        <DialogContent>
          <div className="text-gray-333 font-base">
            <p>{text}</p>
          </div>

          <DialogFooter className="sm:justify-start">
            <Button type="button" onClick={doSuccess}>
              {confirmText}
            </Button>
            <DialogClose asChild>
              <Button type="button" variant="ghost">
                Close & Cancel
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  )
}
