import { Switch } from "~/shadcn/ui/switch"

export const SettingToggle = ({
  id,
  title,
  subTitle,
  icon,
  checked,
  onCheckedChange,
  loading,
}: {
  id: string
  title: string
  subTitle: string
  icon?: React.FC<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
  checked: boolean
  onCheckedChange: (checked: boolean) => void
  loading: boolean
}) => {
  const Icon = icon
  return (
    <div className="p-4 flex flex-row gap-4 justify-beteen items-center">
      <div className="flex flex-row flex-grow">
        <div>{Icon && <Icon className="m-4 w-6 h-6 text-gray-999" />}</div>
        <div className="flex flex-col">
          <label htmlFor={id} className="cursor-pointer">
            <span className="text-base font-semibold text-gray-333">
              {title}
            </span>
            <p className="text-sm text-gray-999">{subTitle}</p>
          </label>
        </div>
      </div>
      <div>
        <div className="flex items-center space-x-2">
          <Switch
            id={id}
            onCheckedChange={onCheckedChange}
            checked={checked}
            disabled={loading}
          />
        </div>
      </div>
    </div>
  )
}
