import { PageHeader } from "~/ui/PageHeader"
import { Button, LinkButton } from "~/shadcn/ui/button"
import { candidateTestPath } from "~/common/paths"
import { gql } from "~/__generated__"
import { useQuery } from "@apollo/client"
import {
  CandidateTestStateEnum,
  Test_CandidateTestTableFragment,
} from "~/__generated__/graphql"

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHead,
  TableRow,
} from "~/ui/tables/Table"
import { Error } from "~/ui/Error"
import { LoadingIndicatorCentered } from "~/components/LoadingIndicator"
import { cn } from "~/common/shadcn-utils"
import { formatDateAndTime } from "~/common/dates"
import { getMetaVar } from "~/common/getMetaVar"
import { useSafeMutation } from "~/common/useSafeMutation"
import toast from "react-hot-toast"
import { usePagination } from "~/common/usePagination"
import { Pagination } from "~/ui/Pagination"
import { TestTime } from "~/tests/TestTime"

const qaToolsEnabled = getMetaVar("qa-tools-enabled") === "true"

const TestTable = ({
  tests,
  handleTestReset,
  withStatus = false,
  withEstimatedTime = false,
}: {
  tests: Test_CandidateTestTableFragment[]
  handleTestReset: (testId: string) => void
  withStatus: boolean
  withEstimatedTime: boolean
}) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Test Name</TableHead>
          {withEstimatedTime && (
            <TableHead className="text-right">Estimated Time</TableHead>
          )}
          {withStatus && <TableHead className="text-right">Status</TableHead>}
          <TableHead className="text-right">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {tests.map((test) => (
          <TableRow key={test.id}>
            <TableCell>
              <div className="text-base">
                {test.name}
                <div className="text-sm text-gray-999">
                  {test.organization.name}
                </div>
              </div>
              <div className="text-gray-999">
                {test.candidateTest?.completedAt ? (
                  <>
                    Completed:{" "}
                    {formatDateAndTime(test.candidateTest.completedAt)}
                  </>
                ) : test.candidateTest?.startedAt ? (
                  <>
                    Started: {formatDateAndTime(test.candidateTest.startedAt)}
                  </>
                ) : null}
              </div>
            </TableCell>
            {withEstimatedTime && (
              <TableCell className="text-right">
                <TestTime test={test} />
              </TableCell>
            )}
            {withStatus && (
              <TableCell>
                <div className="flex flex-row gap-2 items-center justify-end">
                  {test.candidateTest?.state && (
                    <>
                      <div
                        className={cn(
                          "h-1.5 w-1.5 rounded-full",
                          STATE_COLOR[test.candidateTest.state]
                        )}
                      />
                      {STATE_COPY[test.candidateTest.state]}
                    </>
                  )}
                </div>
              </TableCell>
            )}
            <TableCell>
              <div className="flex flex-row gap-2 items-center justify-end">
                {!test.candidateTest?.completedAt ? (
                  <LinkButton to={candidateTestPath({ testId: test.id })}>
                    {test.candidateTest?.startedAt ? "Continue" : "Start"}
                  </LinkButton>
                ) : qaToolsEnabled ? (
                  <Button onClick={() => handleTestReset(test.id)}>
                    Reset (QA)
                  </Button>
                ) : null}
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const PER_PAGE = 10

export const CandidateTestsScreen = () => {
  const myTestsPagination = usePagination({
    perPage: PER_PAGE,
    paramKey: "my_tests_page",
  })
  const promotionalTestsPagination = usePagination({
    perPage: PER_PAGE,
    paramKey: "promo_tests_page",
  })

  const { data, error, loading } = useQuery(
    CANDIDATE_TESTS_SCREEN_QUERY_DOCUMENT,
    {
      variables: {
        myTestsFirst: PER_PAGE,
        myTestsAfter: myTestsPagination.after,
        promotionalTestsFirst: PER_PAGE,
        promotionalTestsAfter: promotionalTestsPagination.after,
      },
    }
  )
  const myTests = data?.myTests?.edges.map((e) => e.node) || []
  const promotionalTests =
    data?.promotionalTests?.edges.map((e) => e.node) || []
  const [candidateTestReset] = useSafeMutation(CANDIDATE_TEST_RESET_MUTATION)

  const handleTestReset = async (testId: string) => {
    const { errors } = await candidateTestReset({
      variables: {
        input: { testId },
      },
    })

    if (errors) {
      toast.error("Error resetting test.")
      console.log(errors)
    }
  }

  if (loading) return <LoadingIndicatorCentered />
  if (error || !data) return <Error message="Error loading tests." />

  return (
    <div className="container mx-auto flex flex-col gap-8 mt-8 pb-12">
      <PageHeader
        title="Tests"
        subhead="Tests available to me in progress and completed."
      />
      {myTests.length > 0 ? (
        <>
          <TestTable
            tests={myTests}
            handleTestReset={handleTestReset}
            withStatus
            withEstimatedTime={false}
          />
          {data?.myTests?.pageCount && data.myTests.pageCount > 1 ? (
            <Pagination
              page={myTestsPagination.page}
              pageCount={data.myTests.pageCount}
              paginate={myTestsPagination.paginate}
            />
          ) : null}
        </>
      ) : (
        <div>You have not yet been invited to take a test.</div>
      )}

      <PageHeader
        title="Community Tests"
        subhead="Other public tests you can take."
      />
      {promotionalTests.length > 0 ? (
        <>
          <TestTable
            tests={promotionalTests}
            handleTestReset={handleTestReset}
            withStatus={false}
            withEstimatedTime
          />
          {data?.promotionalTests?.pageCount &&
          data.promotionalTests.pageCount > 1 ? (
            <Pagination
              page={promotionalTestsPagination.page}
              pageCount={data.promotionalTests.pageCount}
              paginate={promotionalTestsPagination.paginate}
            />
          ) : null}
        </>
      ) : (
        <div>There are no public tests available at this time.</div>
      )}
    </div>
  )
}

const STATE_COLOR = {
  [CandidateTestStateEnum.Completed]: "bg-[#34C759]",
  [CandidateTestStateEnum.InProgress]: "bg-[#FFA800]",
  [CandidateTestStateEnum.NotStarted]: "bg-[#FFA800]",
} as const

const STATE_COPY = {
  [CandidateTestStateEnum.Completed]: "Completed",
  [CandidateTestStateEnum.InProgress]: "In Progress",
  [CandidateTestStateEnum.NotStarted]: "Not Started",
} as const

gql(`
  fragment Test_CandidateTestTable on Test {
    id
    name
    expectedTimeSeconds
    estimatedTimeMinutes

    organization {
      name
    }

    candidateTest {
      id
      state
      startedAt
      completedAt
    }
  }
`)

const CANDIDATE_TESTS_SCREEN_QUERY_DOCUMENT = gql(`
  query CandidateTestsScreen($myTestsFirst: Int!, $myTestsAfter: String, $promotionalTestsFirst: Int!, $promotionalTestsAfter: String) {
    myTests(first: $myTestsFirst, after: $myTestsAfter) {
      pageCount(first: $myTestsFirst)
      edges {
        node {
          ...Test_CandidateTestTable
        }
      }
    }

    promotionalTests(first: $promotionalTestsFirst, after: $promotionalTestsAfter, untakenOnly: true) {
      pageCount(first: $promotionalTestsFirst)
      edges {
        node {
          ...Test_PromotionalTestTable
        }
      }
    }
  }
`)

const CANDIDATE_TEST_RESET_MUTATION = gql(`
  mutation CandidateTestReset($input: CandidateTestResetInput!) {
    candidateTestReset(input: $input) {
      test {
        ...Test_CandidateTestTable
      }
    }
  }
`)
