import { useState } from "react"
import { Button } from "~/shadcn/ui/button"
import { useSafeMutation } from "~/common/useSafeMutation"
import { gql } from "@apollo/client"
import toast from "react-hot-toast"
import { TextareaField } from "~/ui/forms/TextareaField"
import { SelectField } from "~/ui/forms/SelectField"
import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { Form } from "~/shadcn/ui/form"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "~/shadcn/ui/dialog"
import { SquarePen } from "lucide-react"
import { SCORE_SCALE } from "~/questions/ManualScoreDialog"

const formSchema = z.object({
  manualScore: z
    .string()
    .min(0, {
      message: "Must be greater than or equal to 0",
    })
    .max(100, {
      message: "Must be less than or equal to 100",
    }),
  text: z.string().optional(),
})

export const CandidateTestNoteDialog = ({
  candidateTestId,
}: {
  candidateTestId: string
}) => {
  const [createNote] = useSafeMutation(CANDIDATE_TEST_NOTE_CREATE_MUTATION)
  const [dialogOpen, setDialogOpen] = useState(false)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  })

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    console.log("Values", values)
    const { data, errors } = await createNote({
      variables: {
        input: {
          candidateTestId,
          ...values,
        },
      },
    })

    if (!data?.candidateTestNoteCreate?.candidateTest) {
      toast.error("Error creating note.")
      if (errors) {
        console.log(errors)
      }
    } else {
      form.setValue("text", "")
      setDialogOpen(false)
    }
  }

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild={true}>
        <Button
          variant="outline"
          size="sm"
          onClick={() => setDialogOpen(true)}
          className="gap-2 text-gray-333"
        >
          <SquarePen size={16} />
          Grade
        </Button>
      </DialogTrigger>
      <DialogContent className="lg:max-w-3xl">
        <DialogHeader>
          <DialogTitle className="text-xl font-medium text-gray-333 leading-normal tracking-normal">
            Add Internal Feedback on This Candidate
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            <div className="flex flex-col gap-4">
              <SelectField
                control={form.control}
                name="manualScore"
                label="Grade"
                selectEntries={SCORE_SCALE}
                required
              />
              <TextareaField
                name="text"
                label="Notes"
                control={form.control}
                inputClassName="h-48"
              />
            </div>
            <div className="flex items-center gap-6 justify-end">
              <div className="flex gap-4">
                <Button
                  type="button"
                  variant="ghost"
                  onClick={() => {
                    setDialogOpen(false)
                  }}
                  disabled={form.formState.isSubmitting}
                >
                  Cancel
                </Button>
                <Button type="submit" disabled={form.formState.isSubmitting}>
                  Save
                </Button>
              </div>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

const CANDIDATE_TEST_NOTE_CREATE_MUTATION = gql`
  mutation CandidateTestNoteCreate($input: CandidateTestNoteCreateInput!) {
    candidateTestNoteCreate(input: $input) {
      candidateTest {
        id
        notes {
          id
          text
          manualScore
        }
      }
    }
  }
`
