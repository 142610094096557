import { ReactNode } from "react"
import {
  CandidateTest_CandidateTestResponsesFragment,
  Question,
  Test_OrgTestLayoutFragment,
} from "~/__generated__/graphql"
import { format, parseISO } from "date-fns"
import { UserWithImage } from "~/ui/UserWithImage"
import ChevronLeftIcon from "~/images/icons/chevron-left.svg?react"
import { Link } from "react-router-dom"

type CandidateTestLayoutTest = Pick<
  Test_OrgTestLayoutFragment,
  "name" | "updatedAt"
>

type CandidateTestLayoutQuestionResponse = {
  question: Pick<Question, "questionCopy">
}
interface CandidateTestLayoutProps {
  test: CandidateTestLayoutTest | null
  candidateTest?: CandidateTest_CandidateTestResponsesFragment
  questionResponse?: CandidateTestLayoutQuestionResponse
  children: ReactNode
  backPath?: string
  backLabel?: string
  rightModule?: ReactNode
  rightProfile?: ReactNode
  underProfile?: ReactNode
  navigation?: ReactNode
}

export const CandidateTestLayout = ({
  test,
  candidateTest,
  questionResponse,
  children,
  backPath,
  backLabel,
  rightModule,
  rightProfile,
  underProfile,
  navigation,
}: CandidateTestLayoutProps) => {
  return (
    <div className="flex flex-col gap-8">
      {navigation}
      <div className="container mx-auto">
        <div className="flex flex-col divide-y divide-gray-E6E6E3">
          {backPath && (
            <div className="text-gray-333 text-base py-2">
              <Link className="flex flex-row items-center gap-4" to={backPath}>
                <ChevronLeftIcon />
                <span className="text-gray-999">{backLabel}</span>
              </Link>
            </div>
          )}

          {candidateTest && (
            <>
              <div className="py-6 flex items-center gap-4">
                <UserWithImage
                  name={candidateTest.user.name}
                  email={candidateTest.user.email}
                  phone={candidateTest.user.phone}
                  nameClassName="text-gray-333 text-2xl font-medium"
                  emailClassName="text-gray-999"
                  iconClassName="w-[60px] h-[60px]"
                  className="flex-grow"
                  meta={
                    <div className="flex flex-col">
                      {candidateTest.country && (
                        <div className="text-sm text-gray-999">
                          Location:&nbsp;
                          {candidateTest.country}
                          {candidateTest.city && `, ${candidateTest.city}`}
                        </div>
                      )}
                      {candidateTest.completedAt && (
                        <div className="text-sm text-gray-999">
                          Completed&nbsp;
                          {format(
                            parseISO(candidateTest.completedAt),
                            "MMM d, yyyy - h:mm aa"
                          )}
                        </div>
                      )}
                    </div>
                  }
                />
                {rightProfile}
              </div>
              {underProfile}
            </>
          )}

          {test && (
            <div className="flex items-center justify-between gap-8">
              <div className="py-6">
                {questionResponse ? (
                  <div className="text-gray-333 text-2xl font-medium">
                    {questionResponse.question.questionCopy}
                  </div>
                ) : (
                  <>
                    <div className="text-gray-333 text-2xl font-medium">
                      {test.name}
                    </div>
                  </>
                )}
              </div>

              {rightModule}
            </div>
          )}
        </div>

        {children}

        <div className="py-4" />
      </div>
    </div>
  )
}
